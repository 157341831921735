import React from "react"
import Brand from "../brand/Brand"
import Search from "../search/Search"
import NavIcons from "../nav-icons/NavIcons"
import Menu from "../menu/Menu"
import MenuMovil from "../menu/MenuMovil"
import "./Header.css"

export default () => {
  return (
    <header className="header">
      <div className="header-content">
        <MenuMovil />
        <Brand type="logo" filetype="svg" isLink={true} />
        <Menu />
        <div className="search-nav-icons h-list">
          <Search />
          <NavIcons />
        </div>
      </div>
    </header>
  )
}
