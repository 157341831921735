import React from "react"
import { Link } from "gatsby"
import LogoSVG from "../../images/logo.svg"
import IsoSVG from "../../images/logo.svg"
import "./Brand.css"

export default ({ type, filetype, isLink }) => {
  const brand = {
    svg:
      type === "logo" ? (
        <LogoSVG className="logo-svg" />
      ) : (
        <IsoSVG className="iso-svg" />
      ),
  }[filetype]
  return isLink ? (
    <Link className="brand" to="/">
      {brand}
    </Link>
  ) : (
    <div className="brand">{brand}</div>
  )
}
