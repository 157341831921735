import React, { useContext } from "react"
import { Context } from "../../utils/Context"
import { Link } from "gatsby"
import { bagShow } from "../bag/Bag"
import { userShow } from "../user/User"
import "./NavIcons.css"

export default () => {
  const { state, dispatch } = useContext(Context)
  return (
    <nav className="nav-icons h-list">
      <button
        className="user-icon"
        onClick={() => {
          dispatch(userShow())
        }}
      >
        <span className="material-icons">person</span>
      </button>
      <button
        className="bag-icon"
        onClick={() => {
          dispatch(bagShow())
        }}
      >
        <span className="material-icons">shopping_bag</span>
        <span className="bag-icon-number">{state.bag.bagCount}</span>
      </button>
      <Link className="place-icon" to="/tiendas">
        <span className="material-icons">place</span>
      </Link>
    </nav>
  )
}
