import React from "react"
import { Link } from "gatsby"
import SocialIcon from "../social-icon/SocialIcon"
import "./Footer.css"

export default () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <nav className="nav-footer">
          <div className="contact">
            <h4>CONTACTO</h4>
            <p>Escríbenos al número</p>
            <p>Whatsapp</p>
            <a
              href="https://wa.me/51998183881"
              target="_blank"
              rel="noreferrer"
            >
              + 51 998 183 881
            </a>
          </div>
          <div className="stores">
            <h4>TIENDAS</h4>
            <Link to="/tiendas">LIMA</Link>
            <Link to="/tiendas">PROVINCIA</Link>
          </div>
          <div className="stores">
            <h4>GUÍA DE COMPRAS</h4>
            <Link to="/terminos-condiciones">TÉRMINOS Y CONDICIONES</Link>
            <Link to="/cambios-devoluciones">CAMBIOS Y DEVOLUCIONES</Link>
            <Link to="/privacidad-confidencialidad">PRIVACIDAD</Link>
            <Link to="/libro-reclamaciones">LIBRO DE RECLAMACIONES</Link>
          </div>
          <div className="social">
            <h4>SOCIAL</h4>
            <a
              href="https://www.kidsmadehere.com/"
              target="_blank"
              rel="noreferrer"
            >
              FACEBOOK <SocialIcon name="facebook" />
            </a>
            <a
              href="https://www.kidsmadehere.com/"
              target="_blank"
              rel="noreferrer"
            >
              INSTAGRAM <SocialIcon name="instagram" />
            </a>
            <a
              href="https://www.kidsmadehere.com/"
              target="_blank"
              rel="noreferrer"
            >
              TIKTOK <SocialIcon name="tiktok" />
            </a>
            <a
              href="https://www.kidsmadehere.com/"
              target="_blank"
              rel="noreferrer"
            >
              SPOTIFY <SocialIcon name="spotify" />
            </a>
          </div>
        </nav>
        <div className="copy">©2020 Kids Made Here. All Rights Reserved</div>
      </div>
    </footer>
  )
}
