import React, { useContext } from "react"
import { Context } from "../../utils/Context"
import { Link } from "gatsby"
import "./Menu.css"

export default () => {
  const { state } = useContext(Context)
  const arrWomen = [
    "TANK",
    "POLO",
    "CAMI",
    "BODY",
    "VESTIDO",
    "JEAN",
    "COLOR JEAN",
    "PANTALÓN",
    "PANTALÓN DE BUZO & LEGGINS",
    "SHORT",
    "OVERALL & ENTERIZO",
    "SWEATSHIRT",
    "BLUSA",
    "FALDAS",
    "CASACAS & BLAZER",
    "CASACAS DE JEAN",
    "CHOMPA",
    "CHOMPA-POLO",
    "BEACHWEAR",
    "ACCESORIOS Y COMPLEMENTOS",
  ]
  const arrMen = [
    "POLO",
    "CASACAS",
    "SHORT",
    "PAÑUELO",
    "SWEATSHIRT",
    "PANTALÓN & JEANS",
    "GORRO",
    "CHOMPA",
    "CAMISA",
  ]

  const arrCategories = state.config.isWomen ? arrWomen : arrMen
  return (
    <div className="menu">
      <nav className="nav">
        <nav className="nav-gender">
          <div>
            <Link
              className={`nav-gender-item ${
                state.config.isWomen ? "active" : ""
              }`}
              to="/mujer"
            >
              MUJER
            </Link>
          </div>
          <div>
            <Link
              className={`nav-gender-item ${
                state.config.isWomen ? "" : "active"
              }`}
              to="/hombre"
            >
              HOMBRE
            </Link>
          </div>
        </nav>
        <nav className="nav-pages">
          <div>
            <Link className="nav-pages-item" to="/categoria">
              NEW IN
            </Link>
          </div>
          <div>
            <Link className="nav-pages-item" to="/categoria">
              HOMEWEAR <span className="material-icons">home</span>
            </Link>
          </div>
          <div>
            <button className="nav-pages-item active">
              ROPA<span className="material-icons">arrow_drop_down</span>
            </button>
            <nav className="nav-cat">
              {arrCategories.map((item, i) => (
                <div key={i}>
                  <Link className="nav-cat-item" to="/categoria">
                    {item}
                  </Link>
                </div>
              ))}
            </nav>
          </div>
          <div>
            <Link className="nav-pages-item" to="/categoria">
              MÁS VENDIDOS
            </Link>
          </div>
          <div>
            <button className="nav-pages-item">
              PROMOCIONES
              <span className="material-icons">arrow_drop_down</span>
            </button>
            <nav className="nav-cat">
              <div>
                <Link className="nav-cat-item" to="/categoria">
                  special price
                </Link>
              </div>
              <div>
                <Link className="nav-cat-item" to="/categoria">
                  rebajas
                </Link>
              </div>
            </nav>
          </div>
        </nav>
      </nav>
    </div>
  )
}
