import React from "react"
import Button from "../button/Button"
import "./Newsletter.css"

export default () => {
  return (
    <div className="newsletter">
      <p className="newsletter-info">NOVEDADES | PROMOCIONES | LANZAMIENTOS</p>
      <p className="newsletter-call">SUSCRÍBETE A LA NEWSLETTER</p>
      <form className="newsletter-email">
        <div className="newsletter-email-control">
          <label htmlFor="email">DIRECCIÓN DE E-MAIL</label>
          <input
            className="newsletter-email-input"
            type="email"
            id="email"
            name="email"
          />
          <Button className="newsletter-email-submit">enviar</Button>
        </div>
      </form>
    </div>
  )
}
