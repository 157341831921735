import React, { useContext } from "react"
import { Context } from "../../utils/Context"
import Seo from "../seo/Seo"
import Header from "../header/Header"
import Main from "../main/Main"
import Newsletter from "../newsletter/Newsletter"
import Footer from "../footer/Footer"
import "./Layout.css"

export default ({
  children,
  type,
  className,
  slug = null,
  title = null,
  cover = null,
}) => {
  const { state } = useContext(Context)
  return (
    <>
      <div
        className={`layout ${type} ${className} ${
          state.alertTop.isAlertTop ? "is-alert-top" : ""
        }`}
      >
        <Seo slug={slug} title={title} cover={cover} />
        {
          {
            M: (
              <>
                <Main children={children} />
              </>
            ),
            HM: (
              <>
                <Header />
                <Main children={children} />
              </>
            ),
            HMNF: (
              <>
                <Header />
                <Main children={children} />
                <Newsletter />
                <Footer />
              </>
            ),
          }[type]
        }
      </div>
    </>
  )
}
