import React, { useState, useContext } from "react"
import { Context } from "../../utils/Context"
import { Link } from "gatsby"
import "./MenuMovil.css"
import imgMenu from "../../images/img-menu.jpg"

export default () => {
  const { state } = useContext(Context)
  const [isOpen, setIsOpen] = useState(false)
  const [isRopaOpen, setIsRopaOpen] = useState(false)
  const [isPromoOpen, setIsPromoOpen] = useState(false)
  const reset = () => {
    setIsRopaOpen(false)
    setIsPromoOpen(false)
    setIsOpen(false)
  }
  const arrWomen = [
    "TANK",
    "POLO",
    "CAMI",
    "BODY",
    "VESTIDO",
    "JEAN",
    "COLOR JEAN",
    "PANTALÓN",
    "PANTALÓN DE BUZO & LEGGINS",
    "SHORT",
    "OVERALL & ENTERIZO",
    "SWEATSHIRT",
    "BLUSA",
    "FALDAS",
    "CASACAS & BLAZER",
    "CASACAS DE JEAN",
    "CHOMPA",
    "CHOMPA-POLO",
    "BEACHWEAR",
    "ACCESORIOS Y COMPLEMENTOS",
  ]
  const arrMen = [
    "POLO",
    "CASACAS",
    "SHORT",
    "PAÑUELO",
    "SWEATSHIRT",
    "PANTALÓN & JEANS",
    "GORRO",
    "CHOMPA",
    "CAMISA",
  ]

  const arrCategories = state.config.isWomen ? arrWomen : arrMen
  return (
    <div className="mm-menu">
      <button
        className="mm-menu-open"
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <span className="material-icons">menu</span>
      </button>

      <nav className={`mm-nav ${isOpen ? "menu-is-open" : ""}`}>
        <div className="mm-menu-header">
          <nav className="mm-nav-gender">
            <Link
              className={`mm-nav-gender-item ${
                state.config.isWomen ? "active" : ""
              }`}
              onClick={reset}
              to="/mujer"
            >
              MUJER
            </Link>
            <Link
              className={`mm-nav-gender-item ${
                state.config.isWomen ? "" : "active"
              }`}
              onClick={reset}
              to="/hombre"
            >
              HOMBRE
            </Link>
          </nav>
          <button className="mm-menu-close" onClick={() => setIsOpen(false)}>
            <span className="material-icons">close</span>
          </button>
        </div>
        <div className="mm-menu-main">
          <nav className="mm-nav-pages">
            <div>
              <Link
                className="mm-nav-pages-item"
                onClick={reset}
                to="/categoria"
              >
                <img className="card-product-img" src={imgMenu} alt="img" />
                NEW IN
              </Link>
            </div>
            <div>
              <Link
                className="mm-nav-pages-item"
                onClick={reset}
                to="/categoria"
              >
                <img className="card-product-img" src={imgMenu} alt="img" />
                <span>
                  HOMEWEAR <span className="material-icons">home</span>
                </span>
              </Link>
            </div>
            <div>
              <button
                className="mm-nav-pages-item"
                onClick={() => setIsRopaOpen(!isRopaOpen)}
              >
                <img className="card-product-img" src={imgMenu} alt="img" />
                <span>
                  ROPA<span className="material-icons">arrow_drop_down</span>
                </span>
              </button>
              <nav
                className={`mm-nav-cat-ropa ${isRopaOpen ? "ropa-open" : ""}`}
              >
                {arrCategories.map((item, i) => (
                  <Link
                    className="mm-nav-cat-item"
                    onClick={reset}
                    to="/categoria"
                    key={i}
                  >
                    {item}
                  </Link>
                ))}
              </nav>
            </div>
            <div>
              <Link
                className="mm-nav-pages-item"
                onClick={reset}
                to="/categoria"
              >
                <img className="card-product-img" src={imgMenu} alt="img" />
                MÁS VENDIDOS
              </Link>
            </div>
            <div>
              <button
                className="mm-nav-pages-item"
                onClick={() => setIsPromoOpen(!isPromoOpen)}
              >
                <img className="card-product-img" src={imgMenu} alt="img" />
                PROMOCIONES
                <span className="material-icons">arrow_drop_down</span>
              </button>
              <nav
                className={`mm-nav-cat-promo ${
                  isPromoOpen ? "promo-open" : ""
                }`}
              >
                <Link
                  className="mm-nav-cat-item"
                  onClick={reset}
                  to="/categoria"
                >
                  special price
                </Link>
                <Link
                  className="mm-nav-cat-item"
                  onClick={reset}
                  to="/categoria"
                >
                  rebajas
                </Link>
              </nav>
            </div>
          </nav>
        </div>
      </nav>
    </div>
  )
}
